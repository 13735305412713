//FETCH_LIST Actions
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_ERROR = "FETCH_LIST_ERROR";

//PAGE_CHANGE Actions
export const PAGE_CHANGE = "PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const LIMIT_CHANGE = "LIMIT_CHANGE"

//FETCH_LOCATION Actions
export const FETCH_INFO_SUCCESS = "FETCH_INFO_SUCCESS";
export const FETCH_INFO_ERROR = "FETCH_INFO_ERROR";

//ADD_LOCATION Actions
export const ADD_SUCCESS = "ADD_SUCCESS";
export const ADD_ERROR = "ADD_ERROR";

//EDIT_LOCATION Actions
export const EDIT_SUCCESS = "EDIT_SUCCESS";
export const EDIT_ERROR = "EDIT_ERROR";

//DELETE_LOCATION Actions
export const DEL_SUCCESS = "DEL_SUCCESS";
export const DEL_ERROR = "DEL_ERROR";
