import React, { Component } from "react";
import { Provider } from "react-redux";

import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import store from "./store/index";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

//Axios Interceptor
import { axiosInterceptor } from "./middleware";

//Interceptor
import checkAuth from "./authentication/Auth";

import * as ACTIONS from "./store/Login/Actions";
import { AUTHENTICATE_FAILED } from "./store/Login/Types";
import Loader from "react-loader-spinner";
//Check if userdata is available in session storage
const token = JSON.parse(localStorage.getItem("authToken"));
const loggedInUser = JSON.parse(localStorage.getItem("user"));

if (token && loggedInUser) {
  store.dispatch(ACTIONS.authenticate(loggedInUser));
} else {
  store.dispatch({ type: AUTHENTICATE_FAILED });
}

axiosInterceptor();

const loading = () => (
  <span class="text-center loader">
    <Loader type="TailSpin" color="#20a8d8" height={100} width={100} />
  </span>
);

// Components
const DefaultLayout = React.lazy(() => import("./components/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./components/Login"));
const ForgetPassword = React.lazy(() => import("./components/ForgetPassword"));
const ResetPassword = React.lazy(() =>
  import("./components/ResetPassword/ResetPassword")
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ReduxToastr />
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login" component={Login} />
              <Route
                exact
                path="/forgotPassword"
                name="Forgot Password"
                component={ForgetPassword}
              />
              <Route
                exact
                path="/admin/resetPassword/:id"
                name="Reset Password"
                component={ResetPassword}
              />

              <Route
                path="/"
                name="Home"
                component={checkAuth(DefaultLayout)}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
