import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    data: {},
    isEdited:false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                message: action.payload.message,
                error: null
            }
                case ACTION_TYPES.FETCH_ERROR:
               case ACTION_TYPES.EDIT_ERROR :
               case ACTION_TYPES.UPD_W9FORM_ERROR :
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited:false
            }
            case ACTION_TYPES.EDIT_SUCCESS:
                return {
                    ...state,
                    data: action.payload.data,
                    message: action.payload.message,
                    isEdited: true,
                    error: null
                }
                case ACTION_TYPES.UPD_W9FORM_SUCCESS:
                return {
                    ...state,
                    // data: action.payload.data,
                    message: action.payload.message,
                    isEdited: true,
                    error: null
                }
        default:
            return state
    }
}