/**
 * Axios config  setup
 * Set interceptor for global api response error handling
 * Set access token in headers
 */
import axios from "axios";
import store from "../store"
import { LOGOUT } from "../store/Login/Types"

export const axiosInterceptor = () => {

    const token = JSON.parse(localStorage.getItem("authToken"))

    if (token) {
        axios.defaults.headers.common['authorization'] = token
    } else {
        axios.defaults.headers.common['authorization'] = null
    }

    axios.interceptors.request.use(null, error => {
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch({
                    type: LOGOUT
                })
                return Promise.reject(error)
            } else return Promise.reject(error)
        } else if (error.request) {
            let err = {
                response: {
                    data: {
                        message: "Something went wrong,Please try again later!!!"
                    }
                }
            }
            return Promise.reject(err)
        }
    })

    axios.interceptors.response.use(null, (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch({
                    type: LOGOUT
                })
                return Promise.reject(error)
            } else return Promise.reject(error)
        } else if (error.request) {
            let err = {
                response: {
                    data: {
                        message: "Something went wrong,Please try again later!!!"
                    }
                }
            }
            return Promise.reject(err)
        }
    })
}