import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { reducer as formReducer } from "redux-form";
import login from "./Login/Reducer";
import resource from "./Resource/Reducer";
import appinfo from "./Appinfo/Reducer";

const rootReducer = combineReducers({
  toastr: toastrReducer,
  form: formReducer,
  login,
  resource,
  appinfo
});

export default rootReducer;
