//FETCH_CMS Actions
export const FETCH_SUCCESS = "FETCH_SUCCESS"
export const FETCH_ERROR = "FETCH_ERROR"

// CMS EDIT Actions
export const EDIT_SUCCESS = "EDIT_SUCCESS"
export const EDIT_ERROR = "EDIT_ERROR"
// CMS EDIT Actions
export const UPD_W9FORM_SUCCESS = "UPD_W9FORM_SUCCESS"
export const UPD_W9FORM_ERROR = "UPD_W9FORM_ERROR"
