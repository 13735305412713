export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_IMAGE_BASE: API_IMAGE_BASE } = process.env;

//AUTH Module
export const API_LOGIN = `${API_BASE}auth/login`;

export const API_LOGOUT = `${API_BASE}api/logout`;

export const API_CHANGE_PASS = `${API_BASE}api/changePassword`;

export const API_FORGOT_PASS = `${API_BASE}auth/forgotPassword`;

export const API_RESET_PASS = `${API_BASE}auth/resetPassword`;

export const API_UPDATE_PROFILE = `${API_BASE}api/updateProfile`;

//Resources Module
export const API_RESOURCE_LIST = `${API_BASE}api/getResources`;

export const API_GET_RESOURCE = `${API_BASE}api/getResourceDetails`;

export const API_ADD_RESOURCE = `${API_BASE}api/addResource`;

export const API_UPT_RESOURCE = `${API_BASE}api/updateResource`;

export const API_DEL_RESOURCE = `${API_BASE}api/deleteResource`;

//APPINFO Module
export const API_APPINFO_LIST = `${API_BASE}api/getdetails`;
export const API_UPT_APPINFO = `${API_BASE}api/updateInfo`;
