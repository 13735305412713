import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    resourceList: { total: 0, filteredTotal: 0 },
    resource: {},
    start: 0,
    length: 10,
    flagCount: false,
    isEdited: false,
}

const resourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_LIST_SUCCESS:
            return {
                ...state,
                resourceList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.PAGE_CHANGE:
            return {
                ...state,
                start: action.payload
            }
        case ACTION_TYPES.LIMIT_CHANGE:
            return {
                ...state,
                length: action.payload
            }
        case ACTION_TYPES.ADD_SUCCESS:
        case ACTION_TYPES.FETCH_INFO_SUCCESS:
            return {
                ...state,
                resource: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.EDIT_SUCCESS:
            return {
                ...state,
                resource: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_LIST_ERROR:
        case ACTION_TYPES.EDIT_ERROR:
        case ACTION_TYPES.DEL_ERROR:
        return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.FETCH_INFO_ERROR:
        case ACTION_TYPES.ADD_ERROR:
        case ACTION_TYPES.DEL_SUCCESS:
            return {
                ...state,
                resource: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }
}

export default resourceReducer